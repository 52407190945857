/* Add this CSS to your project's stylesheet or component-specific stylesheet */

/* Style for the entire page overlay */
.modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1;
  }
  
  /* Style for the modal container */
  .modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 2;
  }
  
  /* Style for the close button */
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
  }
  
  /* Style for the modal content */
  .modal-content {
    max-width: 400px;
    margin: 0 auto;
  }
  
   .departments-button:hover {
    /* background-color: #0056b3; */
  } 
  
  
  /* Add any other styles you need for your content here */
  
  /* Show the modal when it's open */
  .modal.active,
  .modal-overlay.active {
    display: block;
  }
  