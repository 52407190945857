@font-face {
    font-family: 'Inter';
    src: url('../public/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .myTable{
    margin-top: 30px;
    overflow: auto;
    min-width: 1024px;
        position: unset;
    top: 60px;
    display: block;   
}



.CatmyTable{
  margin-top: 30px;
  overflow: auto;
  min-width: 1024px;
      position: unset;
  top: 60px;
  display: block;   
}
.myLocTable{
  margin-top: 30px;
  overflow: auto;
  min-width: 1024px;
      position: unset;
  top: 60px;
  display: block;   
}

  .ReactModal__Overlay--after-open{
z-index: 5;
overflow: auto;
  }
.ReactModal__Content--after-open{
overflow: auto !important;
width: auto !important;
min-width: auto !important;
background: rgb(242 242 242) !important;
}
.welcomeText{
    font-size: large;
    color: #005668;
    margin-top: 95px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    
  }

  .welcomelocText{
    font-size: xx-large;
    color: #005668;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    margin-top: 2%;
    margin-bottom:0;
  }
  .welcomelocText2{
    font-size: medium;
    color: #005668;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom:0;
  }

  .welcomelocTextUSername{
    font-size: medium;
    color: #005668;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom:0;
  }
  .SVGTextHeading{
    font-size: x-large;
    color: #005668;
    margin-top: 95px;
    align-items: center;
    text-align: center;
    font-weight: bolder;
    text-transform: uppercase;  
  }
  .welcomeTextAdd{
    font-size: large;
    color: #005668;
    margin-top: 95px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
  }

@media (max-width: 1240px) {
.SVGTextHeading{
  font-size: x-large;
}
    .welcomeTextAdd{
        font-size: 14px;
        margin-top: -85px !important;        }
    .add-top-sec{
        position: absolute;
    margin-top: -60px !important;
    z-index: 2;
    width: 93%;
    overflow: hidden;
    background: white;
        }

        .view-top-sec{
          position: absolute;
      margin-top: -60px !important;
      z-index: 2;
      overflow: hidden;
      background: white;
          }
    .myFormAdd{
        overflow: hidden;
    width: 100% !important;
    min-width: 768px;
    }
    .myFormAddParent{
        margin-top: 150px;
        width: 100% !important;
    }
}
@media (max-width: 960px) {

  /* .Catadd-button {
    top: 195px !important;
    left: 500px;    
    width: 200px !important;
} */
  .mySearch {
    display: flex;
    position: absolute;
    top: 115px;
    flex-direction: column;
  }

  .welcomeText {
    margin-top: 95px;
align-items: center;
text-align: right;
margin-right: 40px;
  }

}


  @media (max-width: 635px) {
    .add-button {
      top: 127px !important;
      left: 0 !important;

    }
    .Padd-button {
      top: 127px !important;
      left: 0 !important;

    }
    .Locadd-button {
      top: 127px !important;
      left: 0 !important;

    }

    .myPSearch{
      margin-top: 150px !important;
    }

    .welcomeText {
      top: 50px !important;
      left: 0 !important;
    }
  
  }

  @media (max-width: 512px) {
  .myPagination{
    display: block !important;
  }
  }
  @media (max-width: 599px) {
    .Catadd-button {
      top: 210px !important;
      left: 5px !important;
      width: 200px;
      position: absolute;
    }
  }

  @media (max-width: 397px) {
    .add-button {
      top: 300px !important;
      left: 100px !important;
      width: 200px;
    }
    .Padd-button {
      top: 300px !important;
      left: 100px !important;
      width: 200px;
    }

    .Catadd-button {

    top: 273px !important;
    left: 90px !important;
    width: 200px;
    }
    .Locadd-button {
      top: 235px !important;
      left: 100px !important;
      width: 200px;
    }

    .mySearch {
      top: 145px !important;
  }
    .myTable {
      margin-top: 300px !important;
  }
  
  .CatmyTable {
    margin-top: 185px !important;
}
  .myLocTable{
    margin-top: 130px !important;

  }
    .welcomeText {
      top: 50px !important;
      left: 0 !important;
    }
  }

  @media (min-width: 600px) and (max-width: 720px) {
   
    .Catadd-button {
      margin: 0 auto;
      position: relative !important;
      max-width: 200px;
      top: 100px !important;
            left: 330px;

    }
  }
@media (min-width: 398px) and (max-width: 720px) {


    .myTable{
      margin-top: 235px !important;
    
    }
   .CatmyTable{
      margin-top: 280px !important;
    
    }
    .myLocTable{
      margin-top: 235px !important;
    
    }

    .extraData{
      position: absolute;
      background-color: black;
      height: 500px;
      left: 71px;
      width: 250px;
    }
    .myPSearch{
      margin-bottom: -150px !important;
      margin-top: 200px !important;
    }
    
    .add-button {
      margin: 0 auto;
      position: relative !important;
      max-width: 200px;
      top: 96px;
            left: 330px;

    }

    .Padd-button {
      margin: 0 auto;
      position: relative !important;
      max-width: 200px;
      top: 96px;
            left: 330px;

    }
 
    .Locadd-button {
      margin: 0 auto;
      position: relative !important;
      max-width: 200px;
      top: 96px;
            left: 330px;

    }

    .welcomeText {
      margin: 0 auto;
      position: relative !important;
      max-width: 200px;
      top: 85px;
      
      left: 330px;
    }

    .welcomelocText{

    }

    .text-parent{
        position: absolute;
        top: 25px;
        display: block;
    }

}

.import-button {
  min-width: 200px;
  max-width: 220px;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  font-weight: 500;
  background: #005668;
  font-size: medium;
  height: 38px;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  align-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.add-button {
    min-width: 200px;
    font-family: 'Inter';
    font-style: normal;
    position: absolute;
    right: 7vw; /* This should be a valid CSS value without quotes */
    text-align: center;
    font-weight: 500;
    background: #005668;
    font-size: medium;
    height: 38px;
    border-radius: 10px;
    color: white;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  .Catadd-button{
    min-width: 200px;
    font-family: 'Inter';
    font-style: normal;
    position: absolute;
    right: 7vw; /* This should be a valid CSS value without quotes */
    text-align: center;
    font-weight: 500;
    background: #005668;
    font-size: medium;
    height: 38px;
    top: 190px;
    border-radius: 10px;
    color: white;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  .Locadd-button {
    min-width: 200px;
    font-family: 'Inter';
    font-style: normal;
    position: absolute;
    right: 7vw; /* This should be a valid CSS value without quotes */
    text-align: center;
    font-weight: 500;
    background: #005668;
    font-size: medium;
    height: 38px;
    border-radius: 10px;
    color: white;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }