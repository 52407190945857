@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;400&display=swap');
	body {
		background-color: #ffffff;
		margin: 0;
		overflow: hidden;
	}

    #svgcontainer { width: 100vw; height: 100vh; margin: 0 auto; background: #ffffff }
	.node {
		pointer-events: all;
		cursor: pointer;
		z-index: 1000;
	}
	.node:hover {
		filter: drop-shadow( 2px 3px 4px rgba(0,0,0,.4));
}
.node text {
	pointer-events: none;
	font-family: Inter;
	font-weight: 400;
	fill: white;
	stroke: #ccc;
	stroke-width: 0;

}
	.link {
		shape-rendering: geometricPrecision;
		fill: none;
	}
	div.tooltip {
		position: absolute;
		text-align: center;
		width: 160px;
		height: auto;
		padding: 2px;
		font: bold 16px Dosis,helvetica,sans-serif;
		background: lightsteelblue;
		border: 0px;
		border-radius: 8px;
		pointer-events: none;
	}
	.dropdown .option {
		display: none;
	}
	.dropdown rect {
		stroke-width: 0.5;
		stroke: rgb(0,0,0);
	}
	.dropdown:hover .option {
		display: unset;
	}
	.dropdown text {
		font-family: Arial, helvetica, sans-serif !important;
		fill: white;
		stroke: 0px;
		font-family: Inter;
		font-weight: 400;
		text-shadow:none;	
	}
	.dropdown {
		font-size: 12px;
		fill: rgb(240,240,240);
		cursor: pointer;
	}