@keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .lottie-animation {
    animation: rotateAnimation 40s linear infinite; 
    transform-origin: center; 
  }